import React from 'react';
import './About.css';
import lebron from '../Assets/lebron.jpg'

const About = () => {
  return (
    <div className="about">
      <div className="content-about">

         
                <div className="header-text-container">
                    <h4>ABOUT</h4>
                    <div className='text-image'>
                      <div className="about-text">
                          <p>
                          ML<sup>2</sup> is an advanced regressor with one core tenet: to predict NBA money lines with machine learning. 
                          Metrics used as reliable indicators of performance include previous general player performances 
                          and against the selected opponent, variance in previous performances, and general statistics like 
                          points per game. Our predictive model has approximately achieved an impressive <b>60%</b> accuracy on players’ “unders” 
                          with <b>58%</b> of predictions being within 3 points of the actual performance; a mean absolute error of <b>4.07</b> on validation data. 
                          </p>

                          <p>
                          Our model uses a tree-based regressor built and tuned using <b>XGBoost</b> to predict an NBA player’s points in their upcoming games. 
                          Data was extracted using nba_api and aggregated using pandas. The front end currently exhibited is written using <b> React.js, HTML and 
                          CSS </b> whilst our model is baked into an API using <b> Django </b> and hosted using <b> AWS EC2 and Route 53, Gunicorn, NGINX </b>. 
                          </p>
                      </div>
                      <img src={lebron} alt="Lebron" className='lebron' />
                    </div>
                    
                </div>
      </div>

      
      
    </div>
  );
}

export default About;
