import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Pages/Home';
import Predict from './Pages/Predict';
import About from './Pages/About';
import Contact from './Pages/Contact';


function App() {
  return (
    <div className="App">
      <Navbar/>
      <div id="home">
        <Home/>
      </div>

      <div id="about">
        <About/>
      </div>

      <div id="predict">
        <Predict/>
      </div>

      <div id="contact">
        <Contact/>
      </div>
      
    </div>
  );
}

export default App;
