import React from 'react';
import './Contact.css';
import contactBackground from '../Assets/contactbackground.mp4'
const Contact = () => {
  return (
    <div className="contact">
      <video className="video-bg2" autoPlay loop muted playsInline id='video'>
        <source src={contactBackground} type='video/mp4'/>
      </video>
      <div className="content2">
        <h2 id='top-title'> Have Questions? </h2>
        <h1>Contact Us</h1>
        <div className="button-container2">
          <a href='https://linktr.ee/mlsquared' target='_blank'> 
            <button className="button2">Message Us</button>
          </a>
          
        </div>
      </div>
     
    

    </div>
  );
}

export default Contact;
