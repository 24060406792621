import React, { useState } from 'react';
import './Predict.css';
import SearchBar from '../Components/Searchbar';
import playerList from '../Assets/active_player.json';
import { RotatingSquare } from 'react-loader-spinner'

const Predict = () => {
  const [prediction, setPrediction] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState('');
  const [predictedPlayer, setPredictedPlayer] = useState('');

  const [selectedTeam, setSelectedTeam] = useState('');
  const [playerURL, setPlayerURL] = useState('');
  
  const [playerError, setPlayerError] = useState(false);
  const [teamError, setTeamError] = useState(false);
  const [spinnerActive, setSpinnerActive] = useState(false);

 

  const onSelectedPlayerChange = (player) => { 
    setSelectedPlayer(player);
  };

  const handleSelectChange = (event) => {
    if(event.target.value !== 'UNSELECTED'){
      setTeamError(false);
    }
    else{
      setTeamError(true);
    }
    
    setSelectedTeam(event.target.value);
  };

  const predictModel = async (playerID) => {
    setPlayerURL(`https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${playerID}.png`);
    try {
      setSpinnerActive(true);
      const response = await fetch(`https://mlsquaredbackend.space/api/get_prediction/${playerID}/${selectedTeam}`);
      setSpinnerActive(false);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPrediction(data.prediction); 

    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
    
  };

  const handlePredictClick = async () => {
    setPredictedPlayer(selectedPlayer);

    if(selectedPlayer === ''){
      setPlayerError(true);
    }
    else if(selectedTeam === '' || selectedTeam === 'UNSELECTED'){
      setTeamError(true);
    }
    else{
    
      setPrediction(null);
      setTeamError(false);
      setPlayerError(false);
      
      const getIdIndex = Object.entries(playerList.Names).filter(([key,value]) => {
       
        if (selectedPlayer.toLowerCase() === value.toLowerCase()) {
          console.log("got here");
          
          return key;
        } 
      });
      
      if (getIdIndex.length === 0){
        console.log("COULD NOT FIND THIS PLAYER")
        setPlayerError(true);
      }
      else{
        console.log(playerList.id[getIdIndex[0][0]]);
        
        var playerID = playerList.id[getIdIndex[0][0]];
        predictModel(playerID);
      }

   
    }
  }
  

  return (
    <div className="predict">
      <div className="content3">
        <h5>PREDICT</h5>
        <h7 > Select an opposing team and a player of interest to get started - predictions may take a while!</h7>
        <div className="search-bar-container">
          <div className='custom-select'>
            <select className="team-dropdown" value={selectedTeam} onChange={handleSelectChange}>
              <option value="UNSELECTED">Opposing Team</option>
              <option value="ATL">ATL</option>
              <option value="BOS">BOS</option>
              <option value="BKN">BKN</option>
              <option value="CHA">CHA</option>
              <option value="CHI">CHI</option>
              <option value="CLE">CLE</option>
              <option value="DAL">DAL</option>
              <option value="DEN">DEN</option>
              <option value="DET">DET</option>
              <option value="GSW">GSW</option>
              <option value="HOU">HOU</option>
              <option value="IND">IND</option>
              <option value="LAC">LAC</option>
              <option value="LAL">LAL</option>
              <option value="MEM">MEM</option>
              <option value="MIA">MIA</option>
              <option value="MIL">MIL</option>
              <option value="MIN">MIN</option>
              <option value="NOP">NOP</option>
              <option value="NYK">NYK</option>
              <option value="OKC">OKC</option>
              <option value="ORL">ORL</option>
              <option value="PHI">PHI</option>
              <option value="PHX">PHX</option>
              <option value="POR">POR</option>
              <option value="SAC">SAC</option>
              <option value="SAS">SAS</option>
              <option value="TOR">TOR</option>
              <option value="UTA">UTA</option>
              <option value="WAS">WAS</option>
            </select>
          </div>
          <SearchBar 
            className="search-bar" 
            playerData={playerList}
            onTypedChange={onSelectedPlayerChange}
            isPredicted={predictedPlayer}/>
           
          <div className="button-container">
            <button className="predict-button" onClick={handlePredictClick}>Predict</button>
          </div>
        </div>
        <p className="error-text" > {teamError === false ? "" : 'ERROR: Please select an opposing team'}</p>
        <p className="error-text"> {playerError === false ? "" : 'ERROR: Please select a valid player to predict'}</p>
        <div className='spinner-container'>
        
          {spinnerActive && <RotatingSquare className="spinner" color="white"/>}
        </div>
       
        {!spinnerActive && <p>Prediction: {prediction !== null ? prediction + ' points': 'No prediction yet'}</p>}
        {prediction !== null && !spinnerActive && <img className="Player Image" src={playerURL} alt="Headshot" />}
        
      </div>

    
    </div>
  );
}

export default Predict;
