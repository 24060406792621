import React, { useState, useEffect } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, visible]);

  const handleNavLinkClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    setIsMobileMenuOpen(false); 
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={`navbar ${visible ? 'show' : 'hide'}`}>
      <div className="container">
        <div className="logo">ML<sup>2</sup></div>

        <div className="menu-icon" onClick={toggleMobileMenu}>
          <div className={isMobileMenuOpen ? "menu-icon-line open" : "menu-icon-line"}></div>
          <div className={isMobileMenuOpen ? "menu-icon-line open" : "menu-icon-line"}></div>
          <div className={isMobileMenuOpen ? "menu-icon-line open" : "menu-icon-line"}></div>
        </div>

        <ul className={`nav-links ${isMobileMenuOpen ? "mobile-menu-open" : ""}`}>
          <li><a onClick={() => handleNavLinkClick('home')}>HOME</a></li>
          <li><a onClick={() => handleNavLinkClick('about')}>ABOUT</a></li>
          <li><a onClick={() => handleNavLinkClick('predict')}>PREDICT</a></li>
          <li><a onClick={() => handleNavLinkClick('contact')}>CONTACT</a></li>
        </ul>


      </div>
    </nav>
  );
}

export default Navbar;
