import React, { useState } from 'react';
import './Searchbar.css';


function SearchBar({ playerData, onTypedChange, isPredicted }) {
    const [userPlayerInput, setUserPlayerInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleInput = (event) => {
      const searchWord = event.target.value;
      setUserPlayerInput(searchWord);
      onTypedChange(searchWord);
     
      const newChange = Object.values(playerData.Names).filter((value) => {
        return value.toLowerCase().startsWith(searchWord.toLowerCase());
      });
      if (searchWord === "") {
        setFilteredData([])
      }else {
        setFilteredData(newChange);
      }
    };

    const handleSubmit = (player) => {
      onTypedChange(player);
      const getIdIndex = Object.entries(playerData.Names).filter(([key,value]) => {
        if (player === value) {
          
          return key;
        }
        setIsInputFocused(false);
      });
      
      
      setSelectedPlayer(player);
      setIsSelected(true);
      setTimeout(() => {
        setIsSelected(false);
        setUserPlayerInput('');
      }, 0);
      setFilteredData([]);

    };

    const handleInputFocus = () => {
      setIsInputFocused(true);
    };

    const handleInputBlur = () => {
      setIsInputFocused(false);
    };

    return (
    <div className="search">
      <div className="searchInputs">
        <input
          className="place-text"
          type="text"
          placeholder='Enter a player name'
          onChange={handleInput}
          value={isSelected ? selectedPlayer: null}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <div className="searchIcon">
        
        </div>
      </div>
      {filteredData.length !== 0 && isPredicted.toLowerCase() !== userPlayerInput.toLowerCase() && (
        <div className="dataResult">
          {filteredData.map((value, key) => {
            return (
              <a
                key={key}
                className="dataItem"
                onClick={() => {
                  handleSubmit(value);
                }}
              >
                <p>{value}</p>
              </a>
            );
          })}
        </div>
      )}
     
       
    
    </div>
    );
}

export default SearchBar;