import React from 'react';
import backgroundVideo from '../Assets/nbabackground.mp4'
import './Home.css'; // Assuming you have a CSS file for styling


const Home = () => {

  const predictScroll = () => {
    const section = document.getElementById("predict");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="home">
      <video className="video-bg" autoPlay loop muted playsInline id='video'>
        <source src={backgroundVideo} type='video/mp4'/>
      </video>
      <div className="content-home">
        <h2 id='top-title'> Machine Learning x Money Lines </h2>
        <h1 id='main-title'>ML<sup>2</sup></h1>

        <h3 id='description'> A predictive model for NBA performance</h3>

        
        <div className="button-container-home">
          <button className="button-28" onClick={predictScroll}> Predict Now </button>
        </div>
        
      </div>
    </div>
  );
}

export default Home;
